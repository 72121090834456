import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: { name: "check.authentication" },
    },
    {
      path: "/authenticate",
      name: "check.authentication",
      component: () => import("@/view/pages/Authentication"),
    },
    {
      path: "/admin",
      name: "admin",
      redirect: { name: "admin.dashboard" },
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "consent",
          name: "consent",
          component: () => import("@/view/pages/consent/Consent"),
          meta: {
            requiresAuth: false,
            menuActive: "consent",
            title: "Consent",
          },
        },
        {
          path: "agr-temp",
          name: "agr.temp",
          component: () => import("@/view/pages/agreement/Agr-Template"),
          meta: {
            requiresAuth: false,
            menuActive: "agr",
            title: "Agr",
          },
        },
        {
          path: "dashboard",
          name: "admin.dashboard",
          component: () => import("@/view/pages/Dashboard-New"),
          meta: {
            requiresAuth: true,
            menuActive: "dashboard",
            title: "Dashboard",
          },
        },
        {
          path: "dashboard-new",
          name: "admin.dashboard-new",
          component: () => import("@/view/pages/Dashboard"),
          meta: {
            requiresAuth: true,
            menuActive: "dashboard",
            title: "Dashboard",
          },
        },
        /*{
          path: "service-form",
          name: "admin.service-form",
          component: () => import("@/view/pages/Service-Form"),
          meta: {
            requiresAuth: true,
            menuActive: "service-form",
            title: "Dashboard"
          }
        },*/
        {
          path: "profile",
          name: "admin.profile",
          redirect: { name: "admin.profile.basic" },
          component: () => import("@/view/pages/profile/Profile.vue"),
          children: [
            {
              path: "basic",
              name: "admin.profile.basic",
              component: () => import("@/view/pages/profile/AdminBasic.vue"),
              meta: {
                requiresAuth: true,
                menuActive: "profile",
                title: "Profile",
              },
            },
            {
              path: "setting",
              name: "admin.profile.setting",
              component: () => import("@/view/pages/profile/Setting.vue"),
              meta: {
                requiresAuth: true,
                menuActive: "setting",
                title: "Setting",
              },
            },
            {
              path: "activity",
              name: "admin.profile.activity",
              component: () => import("@/view/pages/profile/Activity.vue"),
              meta: {
                requiresAuth: true,
                menuActive: "activity",
                title: "Activity",
              },
            },
            {
              path: "reminders",
              name: "admin.profile.reminders",
              component: () => import("@/view/pages/profile/Reminder.vue"),
              meta: {
                requiresAuth: true,
                menuActive: "reminders",
                title: "Reminder",
              },
            },
          ],
        },
        {
          path: "project/costing/create",
          name: "admin.project.costing.create",
          component: () =>
            import("@/view/pages/project/costing/CreateCosting.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "project",
            title: "Create Costing | Projects",
          },
        },
        {
          path: "project/costing/update/:id",
          name: "admin.project.costing.update",
          component: () =>
            import("@/view/pages/project/costing/CreateCosting.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "project",
            title: "Update Costing | Projects",
          },
        },
        {
          path: "project/costing/detail/:id",
          name: "admin.project.costing.detail",
          component: () =>
            import("@/view/pages/project/costing/DetailCosting.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "project",
            title: "Detail Costing | Projects",
          },
        },
        {
          path: "project/:project/gantt",
          name: "admin.gantt.chart",
          component: () => import("@/view/pages/gantt/Gantt"),
          meta: {
            requiresAuth: false,
            menuActive: "project",
            title: "Projects | Gantt",
          },
        },
        {
          path: "project",
          name: "admin.project",
          component: () => import("@/view/pages/project/Project.vue"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "project",
            title: "Projects",
          },
        },
        {
          path: "project/create",
          name: "admin.project.create",
          component: () => import("@/view/pages/project/Create-Project.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "project",
            title: "Create Project",
          },
        },
        {
          path: "project/update/:id",
          name: "admin.project.update",
          component: () => import("@/view/pages/project/Update-Project.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "project",
            title: "Update Project",
          },
        },
        {
          path: "project/detail/:id",
          name: "admin.project.detail",
          component: () => import("@/view/pages/project/Detail-Project.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "project",
            title: "Detail Project",
          },
        },
        {
          path: "doc",
          name: "admin.doc",
          component: () => import("@/view/pages/docs/Docs.vue"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "doc",
            title: "Docs",
          },
        },
        {
          path: "doc/create/:id",
          name: "admin.create.doc",
          component: () => import("@/view/pages/docs/Create-Doc.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "doc",
            title: "Create Doc",
          },
        },
        {
          path: "doc/detail/:id",
          name: "admin.detail.doc",
          component: () => import("@/view/pages/docs/Detail-Doc.vue"),
          meta: {
            requiresAuth: true,
            menuActive: "doc",
            title: "Detail Doc",
          },
        },
        {
          path: "bug-reports",
          name: "admin.bug.report",
          component: () => import("@/view/pages/bug-report/Bug-Report.vue"),
          meta: {
            isListing: true,
            requiresAuth: true,
            title: "Bug Report",
          },
        },
        {
          path: "bug-reports/create",
          name: "admin.create.bug.report",
          component: () =>
            import("@/view/pages/bug-report/Create-Bug-Report.vue"),
          meta: {
            requiresAuth: true,
            title: "Create Bug Report",
          },
        },
        {
          path: "bug-reports/detail/:id",
          name: "admin.detail.bug.report",
          component: () =>
            import("@/view/pages/bug-report/Detail-Bug-Report.vue"),
          meta: {
            requiresAuth: true,
            title: "Detail Bug Report",
          },
        },
        {
          path: "email-template/update/:id",
          name: "admin.email.template.update",
          component: () =>
            import("@/view/pages/profile/Setting/CreateEmailTemplate.vue"),
          meta: {
            requiresAuth: true,
            title: "Update Email Template",
          },
        },
        {
          path: "role/create",
          name: "admin.role.create",
          component: () =>
            import("@/view/pages/profile/Setting/CreateRole.vue"),
          meta: {
            requiresAuth: true,
            title: "Create Role",
          },
        },
        {
          path: "role/update/:id",
          name: "admin.role.update",
          component: () =>
            import("@/view/pages/profile/Setting/CreateRole.vue"),
          meta: {
            requiresAuth: true,
            title: "Update Role",
          },
        },
        {
          path: "users",
          name: "admin.profile.users",
          component: () => import("@/view/pages/profile/Users.vue"),
          meta: {
            requiresAuth: true,
            title: "User",
          },
        },
        {
          path: "users/permission/:id",
          name: "admin.profile.userpermission",
          component: () => import("@/view/pages/profile/UserPermission.vue"),
          meta: {
            requiresAuth: true,
            title: "Users Permission",
          },
        },
        {
          path: "calendar",
          name: "admin.calendar",
          component: () => import("@/view/pages/Calendar"),
          meta: {
            requiresAuth: true,
            menuActive: "calendar",
            title: "Calendar",
          },
        },
        {
          path: "calendar/resource",
          name: "admin.calendar.resource",
          component: () => import("@/view/pages/ResourceCalendar"),
          meta: {
            requiresAuth: true,
            menuActive: "calendar",
            title: "Resource Calendar",
          },
        },
        {
          path: "customer",
          name: "admin.customer",
          component: () => import("@/view/pages/customer/Customer"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "customer",
            title: "Customer",
          },
        },
        {
          path: "customer/create",
          name: "admin.customer.create",
          component: () => import("@/view/pages/customer/Create-Customer"),
          meta: {
            requiresAuth: true,
            menuActive: "customer",
            title: "Create | Customer",
          },
        },
        {
          path: "customer/detail/:id",
          name: "admin.customer.detail",
          component: () => import("@/view/pages/customer/Detail-Customer"),
          meta: {
            requiresAuth: true,
            menuActive: "customer",
            title: "Detail | Customer",
          },
        },
        {
          path: "contractor",
          name: "admin.contractor",
          component: () => import("@/view/pages/contractor/Contractor"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "contractor",
            title: "Contractor",
          },
        },
        {
          path: "contractor/create",
          name: "admin.contractor.create",
          component: () => import("@/view/pages/contractor/Create-Contractor"),
          meta: {
            requiresAuth: true,
            menuActive: "contractor",
            title: "Create | Contractor",
          },
        },
        {
          path: "contractor/detail/:id",
          name: "admin.contractor.detail",
          component: () => import("@/view/pages/contractor/Detail-Contractor"),
          meta: {
            requiresAuth: true,
            menuActive: "contractor",
            title: "Detail | Contractor",
          },
        },
        {
          path: "subcontractor",
          name: "admin.subcontractor",
          component: () => import("@/view/pages/subcontractor/SubContractor"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "subcontractor",
            title: "Sub Contractor",
          },
        },
        {
          path: "subcontractor/create",
          name: "admin.subcontractor.create",
          component: () =>
            import("@/view/pages/subcontractor/Create-SubContractor"),
          meta: {
            requiresAuth: true,
            menuActive: "subcontractor",
            title: "Create | Sub Contractor",
          },
        },
        {
          path: "subcontractor/detail/:id",
          name: "admin.subcontractor.detail",
          component: () =>
            import("@/view/pages/subcontractor/Detail-SubContractor"),
          meta: {
            requiresAuth: true,
            menuActive: "subcontractor",
            title: "Detail | Sub Contractor",
          },
        },
        {
          path: "user",
          name: "admin.user",
          component: () => import("@/view/pages/user/User"),
          meta: {
            requiresAuth: true,
            menuActive: "user",
            title: "User",
          },
        },
        {
          path: "user/create",
          name: "admin.user.create",
          component: () => import("@/view/pages/user/Create-User"),
          meta: {
            requiresAuth: true,
            menuActive: "user",
            title: "Create | User",
          },
        },
        {
          path: "user/detail/:id",
          name: "admin.user.detail",
          component: () => import("@/view/pages/user/Detail-User"),
          meta: {
            requiresAuth: true,
            menuActive: "user",
            title: "Detail | User",
          },
        },
        {
          path: "property",
          name: "admin.property",
          component: () => import("@/view/pages/property/Property"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "property",
            title: "Property",
          },
        },
        {
          path: "property/create",
          name: "admin.property.create",
          component: () => import("@/view/pages/property/Create-Property"),
          meta: {
            requiresAuth: true,
            menuActive: "property",
            title: "Create | Property",
          },
        },
        {
          path: "property/detail/:id",
          name: "admin.property.detail",
          component: () => import("@/view/pages/property/Detail-Property"),
          meta: {
            requiresAuth: true,
            menuActive: "property",
            title: "Detail | Property",
          },
        },
        {
          path: "property/update/:id",
          name: "admin.property.update",
          component: () => import("@/view/pages/property/Create-Property"),
          meta: {
            requiresAuth: true,
            menuActive: "property",
            title: "Update | Property",
          },
        },
        {
          path: "quotation",
          name: "admin.quotation",
          component: () => import("@/view/pages/quotation/Quotation"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "quotation",
            title: "Quotation",
          },
        },
        {
          path: "quotation/create",
          name: "admin.quotation.create",
          component: () => import("@/view/pages/quotation/Create-Quotation"),
          meta: {
            requiresAuth: true,
            menuActive: "quotation",
            title: "Create | Quotation",
          },
        },
        {
          path: "quotation/update/:id",
          name: "admin.quotation.update",
          component: () => import("@/view/pages/quotation/Create-Quotation"),
          meta: {
            requiresAuth: true,
            menuActive: "quotation",
            title: "Update | Quotation",
          },
        },
        {
          path: "quotation/detail/:id",
          name: "admin.quotation.detail",
          component: () => import("@/view/pages/quotation/Detail-Quotation"),
          meta: {
            requiresAuth: true,
            menuActive: "quotation",
            title: "Detail | Quotation",
          },
        },
        {
          path: "pre-quotation",
          name: "admin.pre.quotation",
          component: () => import("@/view/pages/pre-quotation/Quotation"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "pre-quotation",
            title: "Quotation",
          },
        },
        {
          path: "pre-quotation/create",
          name: "admin.pre.quotation.create",
          component: () =>
            import("@/view/pages/pre-quotation/Create-Quotation"),
          meta: {
            requiresAuth: true,
            menuActive: "pre-quotation",
            title: "Create | Quotation",
          },
        },
        {
          path: "pre-quotation/update/:id",
          name: "admin.pre.quotation.update",
          component: () =>
            import("@/view/pages/pre-quotation/Create-Quotation"),
          meta: {
            requiresAuth: true,
            menuActive: "pre-quotation",
            title: "Update | Quotation",
          },
        },
        {
          path: "pre-quotation/detail/:id",
          name: "admin.pre.quotation.detail",
          component: () =>
            import("@/view/pages/pre-quotation/Detail-Quotation"),
          meta: {
            requiresAuth: true,
            menuActive: "pre-quotation",
            title: "Detail | Quotation",
          },
        },
        {
          path: "package-sales",
          name: "admin.package.sales",
          component: () => import("@/view/pages/package-sales/Package-Sales"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "package-sales",
            title: "Package-Sales",
          },
        },
        {
          path: "package-sales/create",
          name: "admin.package.sales.create",
          component: () =>
            import("@/view/pages/package-sales/Create-Package-Sales"),
          meta: {
            requiresAuth: true,
            menuActive: "package-sales",
            title: "Create | Package-Sales",
          },
        },
        {
          path: "package-sales/update/:id",
          name: "admin.package.sales.update",
          component: () =>
            import("@/view/pages/package-sales/Create-Package-Sales"),
          meta: {
            requiresAuth: true,
            menuActive: "package-sales",
            title: "Update | Package-Sales",
          },
        },
        {
          path: "package-sales/detail/:id",
          name: "admin.package.sales.detail",
          component: () =>
            import("@/view/pages/package-sales/Detail-Package-Sales"),
          meta: {
            requiresAuth: true,
            menuActive: "package-sales",
            title: "Detail | Package-Sales",
          },
        },
        {
          path: "invoice",
          name: "admin.invoice",
          component: () => import("@/view/pages/invoice/Invoice"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "invoice",
            title: "Invoice",
          },
        },
        {
          path: "invoice/create",
          name: "admin.invoice.create",
          component: () => import("@/view/pages/invoice/Create-Invoice"),
          meta: {
            requiresAuth: true,
            menuActive: "invoice",
            title: "Create | Invoice",
          },
        },
        {
          path: "invoice/detail/:id",
          name: "admin.invoice.detail",
          component: () => import("@/view/pages/invoice/Detail-Invoice"),
          meta: {
            requiresAuth: true,
            menuActive: "invoice",
            title: "Detail | Invoice",
          },
        },
        {
          path: "task",
          name: "admin.task",
          component: () => import("@/view/pages/task/Task"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "task",
            title: "Task",
          },
        },
        {
          path: "task/canban",
          name: "admin.task.canban",
          component: () => import("@/view/pages/task/Canban-Task"),
          meta: { requiresAuth: true, menuActive: "task", title: "Task" },
        },
        {
          path: "task/create",
          name: "admin.task.create",
          component: () =>
            import("@/view/pages/task/Create-Or-Update/Create-Task"),
          meta: {
            requiresAuth: true,
            menuActive: "project",
            title: "Create | Task",
          },
        },
        {
          path: "task/update/:id",
          name: "admin.task.update",
          component: () =>
            import("@/view/pages/task/Create-Or-Update/Update-Task"),
          meta: {
            requiresAuth: true,
            menuActive: "project",
            title: "Update | Task",
          },
        },
        {
          path: "task/detail/:id",
          name: "admin.task.detail",
          component: () => import("@/view/pages/task/Detail/Detail-Task"),
          meta: {
            requiresAuth: true,
            menuActive: "project",
            title: "Detail | Task",
          },
        },
        {
          path: "leads",
          name: "admin.lead",
          component: () => import("@/view/pages/lead/Lead"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "lead",
            title: "Lead",
          },
        },
        {
          path: "leads/create",
          name: "admin.lead.create",
          component: () =>
            import("@/view/pages/lead/Create-Or-Update/Create-Lead"),
          meta: {
            requiresAuth: true,
            menuActive: "lead",
            title: "Create | Lead",
          },
        },
        {
          path: "leads/update/:id",
          name: "admin.lead.update",
          component: () =>
            import("@/view/pages/lead/Create-Or-Update/Create-Lead"),
          meta: {
            requiresAuth: true,
            menuActive: "lead",
            title: "Update | Lead",
          },
        },
        {
          path: "lead/detail/:id",
          name: "admin.lead.detail",
          component: () => import("@/view/pages/lead/Detail/Detail-Lead"),
          meta: {
            requiresAuth: true,
            menuActive: "lead",
            title: "Detail | Lead",
          },
        },
        {
          path: "prospects",
          name: "admin.prospect",
          component: () => import("@/view/pages/prospect/Prospect"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "prospect",
            title: "Prospect",
          },
        },
        {
          path: "prospect/detail/:id",
          name: "admin.prospect.detail",
          component: () =>
            import("@/view/pages/prospect/Detail/Detail-Prospect"),
          meta: {
            requiresAuth: true,
            menuActive: "prospect",
            title: "Detail | Prospect",
          },
        },
        {
          path: "prospect/update/:id",
          name: "admin.prospect.update",
          component: () =>
            import("@/view/pages/prospect/Create-Or-Update/Create-Prospect"),
          meta: {
            requiresAuth: true,
            menuActive: "prospect",
            title: "Update | Prospect",
          },
        },
        {
          path: "agreements",
          name: "admin.agreement",
          component: () => import("@/view/pages/agreement/Agreement"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "agreement",
            title: "Case Trust",
          },
        },
        {
          path: "agreements/form",
          name: "admin.agreement.form",
          component: () => import("@/view/pages/agreement/Create-Agreement"),
          meta: {
            requiresAuth: true,
            menuActive: "agreement",
            title: "Create Agreement | Agreement",
          },
        },
        {
          path: "agreements/form/:id",
          name: "admin.agreement.form.update",
          component: () => import("@/view/pages/agreement/Create-Agreement"),
          meta: {
            requiresAuth: true,
            menuActive: "agreement",
            title: "Update Agreement | Agreement",
          },
        },
        {
          path: "agreements/residential-form",
          name: "admin.agreement.residentialform",
          component: () =>
            import("@/view/pages/agreement/Create-Residential-Agreement"),
          meta: {
            requiresAuth: true,
            menuActive: "agreement",
            title: "Create Agreement | Agreement",
          },
        },
        {
          path: "agreements/residential-form/:id",
          name: "admin.agreement.residentialform.update",
          component: () =>
            import("@/view/pages/agreement/Create-Residential-Agreement"),
          meta: {
            requiresAuth: true,
            menuActive: "agreement",
            title: "Update Agreement | Agreement",
          },
        },
        {
          path: "agreements/create",
          name: "admin.agreement.create",
          component: () =>
            import("@/view/pages/agreement/Create-Or-Update/Create-Agreement"),
          meta: {
            requiresAuth: true,
            menuActive: "agreement",
            title: "Create | Agreement",
          },
        },
        {
          path: "agreements/update/:id",
          name: "admin.agreement.update",
          component: () =>
            import("@/view/pages/agreement/Create-Or-Update/Update-Agreement"),
          meta: {
            requiresAuth: true,
            menuActive: "agreement",
            title: "Update | Agreement",
          },
        },
        {
          path: "agreements/detail/:id",
          name: "admin.agreement.detail",
          component: () =>
            import("@/view/pages/agreement/Detail/Detail-Agreement"),
          meta: {
            requiresAuth: true,
            menuActive: "agreement",
            title: "Detail | Agreement",
          },
        },
        {
          path: "expenses",
          name: "admin.expense",
          component: () => import("@/view/pages/expense/Expense"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "expense",
            title: "Expenses",
          },
        },
        {
          path: "expenses/create",
          name: "admin.expense.create",
          component: () =>
            import("@/view/pages/expense/Create-Or-Update/Create-Expense"),
          meta: {
            requiresAuth: true,
            menuActive: "expense",
            title: "Create | Expense",
          },
        },
        {
          path: "expenses/update/:id",
          name: "admin.expense.update",
          component: () =>
            import("@/view/pages/expense/Create-Or-Update/Update-Expense"),
          meta: {
            requiresAuth: true,
            menuActive: "expense",
            title: "Update | Expense",
          },
        },
        {
          path: "expenses/detail/:id",
          name: "admin.expense.detail",
          component: () => import("@/view/pages/expense/Detail/Detail-Expense"),
          meta: {
            requiresAuth: true,
            menuActive: "expense",
            title: "Detail | Expense",
          },
        },
        {
          path: "proposal",
          name: "admin.proposal",
          component: () => import("@/view/pages/proposal/Proposal"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "proposal",
            title: "Proposals",
          },
        },
        {
          path: "proposal/create",
          name: "admin.proposal.create",
          component: () =>
            import("@/view/pages/proposal/Create-Or-Update/Create-Proposal"),
          meta: {
            requiresAuth: true,
            menuActive: "proposal",
            title: "Create | Proposals",
          },
        },
        {
          path: "proposal/update/:id",
          name: "admin.proposal.update",
          component: () =>
            import("@/view/pages/proposal/Create-Or-Update/Update-Proposal"),
          meta: {
            requiresAuth: true,
            menuActive: "proposal",
            title: "Update | Proposals",
          },
        },
        {
          path: "proposal/detail/:id",
          name: "admin.proposal.detail",
          component: () =>
            import("@/view/pages/proposal/Detail/Detail-Proposal"),
          meta: {
            requiresAuth: true,
            menuActive: "proposal",
            title: "Detail | Proposals",
          },
        },
        {
          path: "payment",
          name: "admin.payment",
          component: () => import("@/view/pages/payment/Payment"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "payment",
            title: "Payment",
          },
        },
        {
          path: "payment/create",
          name: "admin.payment.create",
          component: () => import("@/view/pages/payment/Create-Payment"),
          meta: {
            requiresAuth: true,
            menuActive: "payment",
            title: "Create | Payment",
          },
        },
        {
          path: "payment/detail/:id",
          name: "admin.payment.detail",
          component: () => import("@/view/pages/payment/Detail-Payment"),
          meta: {
            requiresAuth: true,
            menuActive: "payment",
            title: "Detail | Payment",
          },
        },
        {
          path: "product",
          name: "admin.product",
          component: () => import("@/view/pages/product/Product"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "product",
            title: "Product",
          },
        },
        {
          path: "product/create",
          name: "admin.product.create",
          component: () => import("@/view/pages/product/Create-Product"),
          meta: {
            requiresAuth: true,
            menuActive: "product",
            title: "Create | Product",
          },
        },
        {
          path: "product/update/:id",
          name: "admin.product.update",
          component: () => import("@/view/pages/product/Create-Product"),
          meta: {
            requiresAuth: true,
            menuActive: "product",
            title: "Update | Product",
          },
        },
        {
          path: "product/detail/:id",
          name: "admin.product.detail",
          component: () => import("@/view/pages/product/Detail-Product"),
          meta: {
            requiresAuth: true,
            menuActive: "product",
            title: "Detail | Product",
          },
        },
        {
          path: "product/adjustment/create",
          name: "admin.product.adjustment.create",
          component: () =>
            import("@/view/pages/product/adjustment/Create-Adjustment"),
          meta: {
            requiresAuth: true,
            menuActive: "product",
            title: "Create | Product Adjustment",
          },
        },
        {
          path: "adtitle",
          name: "admin.adtitle",
          component: () => import("@/view/pages/adtitle/Adtitle"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "adtitle",
            title: "Additional Works Title",
          },
        },
        {
          path: "service",
          name: "admin.service",
          component: () => import("@/view/pages/service/Service"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "service",
            title: "Service",
          },
        },
        {
          path: "service/create",
          name: "admin.service.create",
          component: () => import("@/view/pages/service/Create-Service"),
          meta: {
            requiresAuth: true,
            menuActive: "service",
            title: "Create | Service",
          },
        },
        {
          path: "service/update/:id",
          name: "admin.service.update",
          component: () => import("@/view/pages/service/Create-Service"),
          meta: {
            requiresAuth: true,
            menuActive: "service",
            title: "Update | Service",
          },
        },
        {
          path: "service/detail/:id",
          name: "admin.service.detail",
          component: () => import("@/view/pages/service/Detail-Service"),
          meta: {
            requiresAuth: true,
            menuActive: "service",
            title: "Detail | Service",
          },
        },
        {
          path: "package",
          name: "admin.package",
          component: () => import("@/view/pages/package/Package"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "package",
            title: "Package",
          },
        },
        {
          path: "package/create",
          name: "admin.package.create",
          component: () => import("@/view/pages/package/Create-Package"),
          meta: {
            requiresAuth: true,
            menuActive: "package",
            title: "Create | Package",
          },
        },
        {
          path: "package/update/:id",
          name: "admin.package.update",
          component: () => import("@/view/pages/package/Create-Package"),
          meta: {
            requiresAuth: true,
            menuActive: "package",
            title: "Update | Package",
          },
        },
        {
          path: "package/detail/:id",
          name: "admin.package.detail",
          component: () => import("@/view/pages/package/Detail-Package"),
          meta: {
            requiresAuth: true,
            menuActive: "package",
            title: "Detail | Package",
          },
        },
        {
          path: "leave",
          name: "admin.leave",
          component: () => import("@/view/pages/leave/Leave"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "leave",
            title: "Leave",
          },
        },
        {
          path: "leave/create",
          name: "admin.leave.create",
          component: () =>
            import("@/view/pages/leave/Create-Or-Update/Create-Leave"),
          meta: {
            requiresAuth: true,
            menuActive: "leave",
            title: "Create | Leave",
          },
        },
        {
          path: "leave/update/:id",
          name: "admin.leave.update",
          component: () =>
            import("@/view/pages/leave/Create-Or-Update/Update-Leave"),
          meta: {
            requiresAuth: true,
            menuActive: "leave",
            title: "Update | Leave",
          },
        },
        {
          path: "leave/detail/:id",
          name: "admin.leave.detail",
          component: () => import("@/view/pages/leave/Detail/Detail-Leave"),
          meta: {
            requiresAuth: true,
            menuActive: "leave",
            title: "Detail | Leave",
          },
        },
        {
          path: "report/project",
          name: "admin.report.project",
          component: () => import("@/view/pages/report/Project-Report"),
          meta: {
            requiresAuth: true,
            menuActive: "project-report",
            title: "Project Report",
          },
        },
        {
          path: "report/sales",
          name: "admin.report.sales",
          component: () => import("@/view/pages/report/Sales-Report"),
          meta: {
            requiresAuth: true,
            menuActive: "sales-report",
            title: "Sales Report",
          },
        },
        {
          path: "report/invoices",
          name: "admin.report.invoices",
          component: () => import("@/view/pages/report/Invoice-Report"),
          meta: {
            requiresAuth: true,
            menuActive: "invoices-report",
            title: "Invoice Report",
          },
        },
        {
          path: "purchase-order",
          name: "admin.purchase.order",
          component: () =>
            import("@/view/pages/purchase-order/Purchase-Order.vue"),
          meta: {
            isListing: true,
            requiresAuth: true,
            menuActive: "purchase-order",
            title: "Purchase Orders",
          },
        },
        {
          path: "purchase-order/create",
          name: "admin.purchase.order.create",
          component: () =>
            import(
              "@/view/pages/purchase-order/Create-Or-Update/Create-Purchase-Order.vue"
            ),
          meta: {
            requiresAuth: true,
            menuActive: "purchase-order",
            title: "Create Purchase Order",
          },
        },
        {
          path: "purchase-order/update/:id",
          name: "admin.purchase.order.update",
          component: () =>
            import(
              "@/view/pages/purchase-order/Create-Or-Update/Update-Purchase-Order.vue"
            ),
          meta: {
            requiresAuth: true,
            menuActive: "purchase-order",
            title: "Update Purchase Order",
          },
        },
        {
          path: "purchase-order/raise/:id/:action",
          name: "admin.purchase.order.raise",
          component: () =>
            import(
              "@/view/pages/purchase-order/Create-Or-Update/Update-Purchase-Order.vue"
            ),
          meta: {
            requiresAuth: true,
            menuActive: "purchase-order",
            title: "Raise Purchase Order",
          },
        },
        {
          path: "purchase-order/detail/:id",
          name: "admin.purchase.order.detail",
          component: () =>
            import(
              "@/view/pages/purchase-order/Detail/Detail-Purchase-Order.vue"
            ),
          meta: {
            requiresAuth: true,
            menuActive: "purchase-order",
            title: "Detail Purchase Order",
          },
        },
      ],
    },
    {
      path: "/admin/login",
      name: "admin.login",
      component: () => import("@/view/pages/auth/Login"),
      meta: { requiresAuth: false, title: "Admin | Login" },
    },
    {
      path: "/customer/quotation/approve",
      name: "customer.quotation.approve",
      component: () => import("@/view/customer/quotation/Approve"),
      meta: { requiresAuth: false, title: "Approve | Quotation | Customer" },
    },
    {
      path: "/quotation/approve",
      name: "quotation.approve",
      component: () => import("@/view/pre-quotation/Approve"),
      meta: { requiresAuth: false, title: "Approve | Quotation" },
    },
    {
      path: "/customer/package/approve",
      name: "customer.package.approve",
      component: () => import("@/view/customer/package/Approve"),
      meta: { requiresAuth: false, title: "Approve | Package | Customer" },
    },
    {
      path: "/customer/agreement/approve",
      name: "customer.agreement.approve",
      component: () => import("@/view/customer/agreement/Approve"),
      meta: { requiresAuth: false, title: "Approve | Agreement | Customer" },
    },
    {
      path: "/customer/agreement/resident/approve",
      name: "customer.agreement.resident.approve",
      component: () => import("@/view/customer/agreement/Resident-Approve"),
      meta: { requiresAuth: false, title: "Approval | Agreement | Customer" },
    },
    {
      path: "/document/:id/public",
      name: "document.public",
      component: () => import("@/view/pages/docs/Public-Doc.vue"),
      meta: { requiresAuth: false, title: "Document" },
    },
    {
      path: "/package-sales/:id/generate",
      name: "package.sales.generate",
      component: () => import("@/view/pages/docs/Generate-Package-Sales.vue"),
      meta: { requiresAuth: false, title: "Generate Package Sales PDF" },
    },
    {
      path: "/shortened-url/:url",
      name: "shortened.url",
      component: () => import("@/view/pages/Short-URL.vue"),
      meta: { requiresAuth: false, title: "Short URL" },
    },
    {
      path: "/quotation/case-trust",
      name: "quotation.case.trust",
      component: () => import("@/view/pages/auth/Login"),
      meta: { requiresAuth: false, title: "Admin | Login" },
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1"),
      meta: { requiresAuth: false, title: "Page Not Found" },
    },
  ],
});
